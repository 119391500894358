<template>
  <div>
    <span
      class="link mb-2"
      @click="back"
    > Назад </span>
    <b-row class="mt-2 align-items-center">
      <b-col cols="9">
        <b-button
          v-if="knoxInfo && knoxInfo.status == 'blocked'"
          variant="outline-danger"
          class="btn-tour-skip w-100"
        >
          <span class="align-middle">
            <feather-icon icon="LockIcon" />
            Bloklangan</span>
        </b-button>
      </b-col>
      <b-col cols="3">
        <div class="d-flex justify-content-end align-items-center mt-1">
          Status:
          <b-button
            variant="outline-info"
            class="ml-2 btn-tour-skip text-uppercase"
          >
            <span class="align-middle"> {{ knoxInfo.status }}</span>
          </b-button>
          <b-overlay :show="loadingBtn">
            <b-button
              v-if="knoxInfo.status == 'uploaded'"
              variant="primary"
              class="ml-2 text-uppercase"
              @click="approveKnox"
            >
              <span class="align-middle"> Tasdiqlash</span>
            </b-button>
          </b-overlay>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col cols="12">
        <b-card class="earnings-card">
          <div class="d-flex justify-content-between align-middle">
            <h3 class="mb-2">
              Biriktirilgan shartnoma ma'lumotlari
            </h3>
            <!-- <b-button
              v-if="
                knoxInfo &&
                  knoxInfo.contract_id &&
                  knoxInfo.contract_product_id &&
                  knoxInfo.contract
              "
              variant="outline-danger"
              class="float-right"
              size="small"
              @click="openModal('detach')"
            >
              Knoxni Uzish
            </b-button> -->
          </div>
          <div
            v-if="
              knoxInfo &&
                knoxInfo.contract_id &&
                knoxInfo.contract_product_id &&
                knoxInfo.contract
            "
          >
            <p v-if="knoxInfo.contract.client">
              <b>Biriktirilgan mijoz</b>:
              <span
                class="link"
                @click="
                  $router.push({
                    name: 'payment-show',
                    params: { id: knoxInfo.contract.id },
                  })
                "
              >
                {{
                  [
                    knoxInfo.contract.client.lastname,
                    knoxInfo.contract.client.firstname,
                    knoxInfo.contract.client.middlename,
                  ].join(" ")
                }}
                ({{
                  knoxInfo.contract.client.doc_series +
                    knoxInfo.contract.client.doc_number +
                    " / " +
                    knoxInfo.contract.client.pin
                }})
              </span>
            </p>
            <p>
              <b>Mijoz telefoni</b>: +998{{ knoxInfo.contract.client.phone }};
              Yaqin qarindoshlari raqamlari: +998{{
                knoxInfo.contract.near_phone1
              }}, +998{{ knoxInfo.contract.near_phone2 }}
            </p>
            <p v-if="knoxInfo.contract">
              <b>Shartnoma summasi</b>:
              {{ knoxInfo.contract.credit_amount | formatPrice }} so'm
            </p>
            <p v-if="knoxInfo.contract_product">
              <b>Sotib olingan mahsulot</b>:
              {{ knoxInfo.contract_product.category.name_uz }}({{
                knoxInfo.contract_product.barcode
              }})
            </p>
            <p v-if="knoxInfo.contract.contract_pdf_url">
              <b>Shartnoma</b>:
              <a
                :href="storageUrlG + knoxInfo.contract.contract_pdf_url"
                target="_blank"
                class="link"
              >
                <feather-icon icon="PaperclipIcon" />Shartnoma</a>
            </p>
            <p>
              <b>Yaratilgan sana</b>:
              {{ dateDotFormat(knoxInfo.contract.created_at) }}
            </p>
            <p>
              <b>IMEI 1</b>:
              {{ knoxInfo.contract_product.imei_1 }}
            </p>
            <p>
              <b>IMEI 2</b>:
              {{ knoxInfo.contract_product.imei_2 }}
            </p>
          </div>
          <div v-else>
            <span class="text-muted">iCloud biriktirilmagan</span>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <!-- Messages -->
      <b-col cols="6">
        <b-card class="earnings-card">
          <h3>Xabarlar</h3>
          <b-button
            variant="outline-success"
            class="btn-tour-skip"
            @click="openModal('signal')"
          >
            <span class="align-middle">
              <feather-icon icon="PlusIcon" />
              Xabar qo'shish</span>
          </b-button>
          <hr>
          <div v-if="knoxInfo && knoxInfo.messages && knoxInfo.messages.length">
            <div
              v-for="(item, index) in knoxInfo.messages"
              :key="index"
              class="info-block"
            >
              <div>
                <b>{{
                  item.response_data && item.response_data.result == "SUCCESS"
                    ? "Xabar yuborildi"
                    : "Yuborishda xatolik"
                }}</b>: {{ formatDateYmd(item.created_at) }}, <b>Xabar</b>:
                {{ item.message }}
                <span
                  v-if="item.created_user"
                >({{ item.created_user.name }})</span>
              </div>
              <div>
                Status:
                <i v-if="item.response_data">{{
                  item.response_data.result
                    ? item.response_data.result
                    : item.response_data
                }}</i>
              </div>
              <b-button
                v-if="item.response_data && item.response_data.objectId"
                v-b-tooltip.hover.html="
                  '<p>ObjectId: ' +
                    item.response_data.objectId +
                    '</p>' +
                    '<p>RequestedId: ' +
                    item.response_data.requestedId +
                    '</p>' +
                    '<p>Result: ' +
                    item.response_data.result +
                    '</p>'
                "
                variant="outline-info"
                class="ml-2 btn-tour-skip text-uppercase"
              >
                <span class="align-middle"> Info</span>
              </b-button>
              <b-button
                v-else-if="item.response_data"
                v-b-tooltip.hover
                variant="outline-info"
                :title="item.response_data"
                class="ml-2 btn-tour-skip text-uppercase"
              >
                <span class="align-middle">Info</span>
              </b-button>
            </div>
          </div>
          <div v-else>
            Xabar yuborilmagan
          </div>
        </b-card>
      </b-col>

      <!-- BLOCKS -->
      <b-col cols="6">
        <b-card class="earnings-card">
          <h3>Bloklashlar</h3>
          <b-button
            v-if="knoxInfo.status == 'blocked'"
            v-can="'knoxes.block'"
            variant="outline-danger"
            class="btn-tour-skip"
            @click="openModal('block')"
          >
            <span class="align-middle">
              <feather-icon icon="UnlockIcon" />
              {{ "Blokdan chiqarish" }}
            </span>
          </b-button>
          <b-button
            v-else-if="knoxInfo.status == 'approved'"
            v-can="'knoxes.unblock'"
            variant="outline-danger"
            class="btn-tour-skip"
            @click="openModal('block')"
          >
            <span class="align-middle">
              <feather-icon icon="LockIcon" />
              {{ "Bloklash" }}
            </span>
          </b-button>
          <hr>
          <div v-if="knoxInfo && knoxInfo.blocks && knoxInfo.blocks.length">
            <div
              v-for="(item, index) in knoxInfo.blocks"
              :key="index"
              class="danger-block"
            >
              <div>
                <b class="text-uppercase">{{ item.status }}</b>: {{ formatDateYmd(item.created_at) }}, <b>Izoh</b>:
                {{ item.message }}
                <span
                  v-if="item.created_user"
                >({{ item.created_user.name }})</span>
              </div>
              <div>Telefon: {{ item.phone }}</div>
              <div>
                Status:
                <i v-if="item.response_data">{{
                  item.response_data.result
                    ? item.response_data.result
                    : item.response_data
                }}</i>
              </div>
              <b-button
                v-if="item.response_data && item.response_data.objectId"
                v-b-tooltip.hover.html="
                  '<p>ObjectId: ' +
                    item.response_data.objectId +
                    '</p>' +
                    '<p>RequestedId: ' +
                    item.response_data.requestedId +
                    '</p>' +
                    '<p>Result: ' +
                    item.response_data.result +
                    '</p>'
                "
                variant="outline-info"
                class="ml-2 btn-tour-skip text-uppercase"
              >
                <span class="align-middle"> Info</span>
              </b-button>
              <b-button
                v-else-if="item.response_data"
                v-b-tooltip.hover
                :title="item.response_data"
                variant="outline-info"
                class="ml-2 btn-tour-skip text-uppercase"
              >
                <span class="align-middle"> Info</span>
              </b-button>
            </div>
          </div>
          <div v-else>
            Hali bloklanmagan
          </div>
        </b-card>
      </b-col>
    </b-row>
    <div
      v-if="knoxInfo.contract_products && knoxInfo.contract_products.length"
      class="w-100"
    >
      <b-card class="w-100 earnings-card">
        <h3>Biriktirish tarixi</h3>

        <table class="table table-hover table-responsive w-100">
          <thead>
            <tr>
              <th>№</th>
              <th>Shartnoma</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(contract, index) in knoxInfo.contract_products">
              <tr :key="index">
                <td>{{ index + 1 }}</td>
                <td class="w-100">
                  <p>
                    <b>Shartnoma: </b>

                    <span
                      class="link"
                      @click="
                        $router.push({
                          name: 'payment-show',
                          params: { id: contract.contract_id },
                        })
                      "
                    >Shartnoma</span>
                  </p>
                  <p><b>Mahsulot: </b>{{ contract.category.name_uz }}</p>
                  {{ contract.name }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </b-card>
    </div>
    <b-modal
      v-model="signalModal"
      size="md"
      centered
      hide-footer
      :title="modalTitle"
      no-close-on-backdrop
      @close="signalModal = false"
      @cancel="signalModal = false"
    >
      <p
        v-if="clickFor == 'detach'"
        class="font-wight-bold"
      >
        iCloudni uzish orqali, ilgari biriktirilgan foydalanuvchi icloudi
        uziladi
      </p>
      <b-form
        v-else
        class="auth-login-form mt-2"
        @submit.prevent="addAction"
      >
        <b-form-group
          v-if="clickFor != 'signal'"
          label="Aloqa uchun telefon raqam"
          label-for="tag"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              +998
            </b-input-group-prepend>

            <b-form-input
              id="v-phone"
              v-model="contactPhone"
              v-mask="'#########'"
              name="v-phone"
            />
          </b-input-group>
        </b-form-group>

        <b-form-group
          label="Izoh"
          label-for="tag"
        >
          <b-form-textarea
            id="body"
            v-model="comment"
            class="form-control"
            placeholder="Комментарий"
            rows="3"
          />
        </b-form-group>
      </b-form>
      <b-row>
        <b-overlay :show="loadingBtn">
          <b-button
            variant="primary"
            class="float-right"
            size="small"
            @click="addAction"
          >
            <feather-icon
              v-if="clickFor != 'detach'"
              :icon="clickFor == 'signal' ? 'SendIcon' : 'LockIcon'"
            />
            {{
              clickFor == "signal"
                ? "Yuborish"
                : clickFor == "detach"
                  ? "Uzish"
                  : knoxInfo.status == "approved"
                    ? "Bloklash"
                    : "Blokdan chiqarish"
            }}
          </b-button>
        </b-overlay>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { showToast } from '@/utils/toast'
import { BFormGroup, BFormInput } from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      knoxInfo: null,
      comment: '',
      contactPhone: null,
      signalModal: false,
      clickFor: 'signal',
      showPassword: false,
      loadingBtn: false,
      fields: [
        {
          key: 'number',
          label: '№',
        },
        {
          key: 'date',
          label: 'Дата',
        },
        {
          key: 'amount',
          label: 'Сумма',
        },
      ],
    }
  },
  computed: {
    modalTitle() {
      switch (this.clickFor) {
        case 'signal':
          return 'Xabar yuborish'
        case 'detach':
          return 'ICloudni uzish'

        default:
          return this.knoxInfo.status == 'approved'
            ? 'Bloklash'
            : 'Blokdan chiqarish'
      }
    },
  },
  mounted() {
    this.getAction()
  },
  methods: {
    ...mapActions({
      getItem: 'resource/getKnox',
      detachICloud: 'resource/detachICloud',
      addSignalKnox: 'resource/addSignalKnox',
      addBlockKnox: 'resource/addBlockKnox',
      unblockKnox: 'resource/unblockKnox',
      knoxApprove: 'resource/knoxApprove',
    }),
    async approveKnox() {
      this.loadingBtn = true
      await this.knoxApprove({
        knox_id: this.$route.params.id,
      })
        .then(res => {
          showToast('success', this.$t('Tasdiqlandi'))
          this.getAction()
        })
        .finally(() => {
          this.loadingBtn = false
        })
    },
    getAction() {
      this.getItem({
        id: this.$route.params.id,
        relations:
          'messages|blocks|contract|contract.client|contractProduct|contractProduct.category',
      }).then(res => {
        this.knoxInfo = res.data
      })
    },
    addAction() {
      this.loadingBtn = true
      this.method()
        .then(res => {
          this.signalModal = false
          showToast('success', this.$t('Muvaffaqiyatli amalga oshirildi'))
          this.getAction()
        })
        .finally(() => {
          this.loadingBtn = false
        })
    },
    openModal(type) {
      this.clickFor = type
      this.signalModal = true
    },
    detachICloudAction() {
      this.detachICloud({ icloud_id: this.$route.params.id }).then(res => {
        if (res.success) {
          showToast('success', this.$t('Muvaffaqiyatli Uzildi'))
          this.getAction()
        }
      })
    },
    method() {
      if (this.clickFor == 'signal') {
        return this.addSignalKnox({
          knox_id: this.$route.params.id,
          message: this.comment,
        })
      }
      if (this.clickFor == 'detach') {
        return this.detachICloud({ icloud_id: this.$route.params.id })
      }
      if (this.knoxInfo.status == 'blocked') {
        return this.unblockKnox({
          knox_id: this.$route.params.id,
          phone: this.contactPhone,
          message: this.comment,
        })
      }
      return this.addBlockKnox({
        knox_id: this.$route.params.id,
        phone: this.contactPhone,
        message: this.comment,
      })
    },
  },
}
</script>

<style></style>
